<!--
 * @Author: lbh
 * @Date: 2021-04-28 11:28:01
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-27 14:17:03
 * @Description: GSPOS 落單系統
-->
<template>
  <div
    class="gspos-box t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="products"
      child='gspos'
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        :title="$t('gspos.bigTitle')"
        :type="2"
        :wtype="window.type"
        :image="getImgPath('pbannerss.jpg')"
        :bgImage="getImgPath('b1.jpg')"
      />
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :datas="graphic.datas"
        :height="graphic.height"
        :title="$t('gspos.bigTitle')"
      />
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfGraphic from "@/components/commo/graphic";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfGraphic
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      graphic: {
        imgs: [],
        datas: [],
        height: 0
      }
    }
  },
  mounted () {
    util.setTitle(`${this.$t('gspos.bigTitle')} – ${this.$t('companyName')}`);
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    // 初始化數據
    let imgs = [util.getImgPath('ppic-1-1.jpg')];
    this.graphic.imgs = imgs;
    let datas = [
      {
        title: this.$t('gspos.labelA.title'), items: [
          { title: `– ${this.$t('gspos.labelA.itemA')}` },
          { title: `– ${this.$t('gspos.labelA.itemB')}` },
          { title: `– ${this.$t('gspos.labelA.itemC')}` },
          { title: `– ${this.$t('gspos.labelA.itemD')}` },
          { title: `– ${this.$t('gspos.labelA.itemF')}` },
          { title: `– ${this.$t('gspos.labelA.itemG')}` },
          { title: `– ${this.$t('gspos.labelA.itemH')}` },
          { title: `– ${this.$t('gspos.labelA.itemI')}` },
          { title: `– ${this.$t('gspos.labelA.itemJ')}` },
          { title: `– ${this.$t('gspos.labelA.itemK')}` },
          { title: `– ${this.$t('gspos.labelA.itemM')}` },
          { title: `– ${this.$t('gspos.labelA.itemN')}` },
          { title: `– ${this.$t('gspos.labelA.itemO')}` },
          { title: `– ${this.$t('gspos.labelA.itemQ')}`},
          { title: `– ${this.$t('gspos.labelA.itemR')}` },
          { title: `– ${this.$t('gspos.labelA.itemS')}` }
        ],
      },
      {
        title: this.$t('gspos.labelB.title'), items: [
          { title: this.$t('gspos.labelB.itemA') }
        ],
      },
      {
        title: this.$t('gspos.labelC.title'), items: [
          { title: this.$t('gspos.labelC.itemA')}
        ],
      },
      {
        title:  this.$t('gspos.labelD.title'), items: [
          { title: this.$t('gspos.labelD.itemA') }
        ]
      }];
    this.graphic.datas = datas;
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.2);
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
        this.graphic.height = (w * 0.8 - 40) * 0.8;
      } else {
        this.window.type = '1000';
        this.graphic.height = (w * 0.8 / 2 - 40) * 0.8;
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
</style>
